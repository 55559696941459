import React, { useLayoutEffect, useRef } from 'react'
import { useWindowScroll } from 'react-use';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logoCrystal from '../hexCrystalTransparent.png'

const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 64em){
font-size: ${props => props.theme.fontxxl};
}
`

const LogoImage =  styled.div`
transition: all 0.2s ease;
&:hover{
    transform: scale(1.1);
}
width: 5rem;
`

const StyledImg = styled.img`
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-size: 2rem;
display: flex;
align-items: center;
width: -webkit-fill-available;

@media (max-width: 40em){
 width: 3rem;
  }

`
const Logo = () => {


const ref = useRef(null);
const {y} = useWindowScroll();

const scrollToTop = () => {

    let element = document.getElementById("riftOfMagic");
  
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }

return (
  <LogoImage onClick={scrollToTop}> 
       <Link to="/">
        <StyledImg src={logoCrystal}/>
       </Link>
    </LogoImage>
)
}

export default Logo