import React, { lazy, Suspense } from 'react'
import styled, { keyframes } from 'styled-components'
import Background from '../../assets/HeaderBackground.jpg';
// import CoverVideo from '../CoverVideo'
// import TypeWriterText from '../TypeWriterText'
//import RoundTextBlack from '../../assets/Rounded-Text-Black.png';
import Loading from '../Loading';

import logoArrow from '../../assets/logo-arrow.svg'
import cristal from '../../hexCrystalTransparent.png'
//const CoverVideo = lazy(() => import('../CoverVideo'));
const TypeWriterText = lazy(() => import('../TypeWriterText'));

const Section = styled.section`
width: 100vw;
position: relative;
min-height: 66vh;
background-color: ${props => props.theme.background};
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
padding-top: 3rem;
padding-bottom: 3rem;
@media (max-width: 64em) {
  min-height: 100%;
  padding: 3rem;
  width: auto;
}
@media (max-width: 48em) {
  min-height: 100%;
  padding: 3rem;
  width: auto;
  }
}
`

const Container = styled.div`
width: 75%;
min-height: 66vh;
margin: 0 auto;

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  width: 85%;
  min-height: 33vh;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  min-height: 33vh;
  width: 100%;
  &>*:first-child{
    width: 100%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-height: 20vh;
}
`

const LineDrawToRight = styled.div`
  width: 100%;
  height: 3px;
  background: deeppink;
  border-radius: 3px;
  transition: width 1s ease;
  margin:6rem;
  @media (max-width: 40em){
    margin:2rem;
  }
  
`
const BigBox = styled.div`
width: 100%;
height: 100%;
text-align-last: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
transition: width 1s ease;

&:hover ${LineDrawToRight} {
  width: 100%;
  transition: width 1s ease;
}
@media (max-width: 40em){
}
`
const rotate = keyframes`
100%{
  transform: rotate(1turn);
}`

const Cristal = styled.img`
  width: 3rem;
  height: auto;
  animation: ${rotate} 6s linear infinite reverse ;
  @media (max-width: 40em){
 width: 3rem;
  }
}`

const TitleContainer = styled.div`
width: fit-content;
text-align-last: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;

  color: ${props => props.theme.text};
  align-self: flex-start;
  width: 100%;
margin: 0 auto;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontxl};
  color: ${props => props.theme.text};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontlg};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const ArrowImageForList = styled.img`
width: 3rem;
margin-right: 1rem; 
@media (max-width: 40em){
  margin: 0;
  width: 1em;

}
`
const CrystalBox = styled.div`
align-self: center;
width: 80%;
margin: 0 auto;
padding-top: 3rem;
`

const KeyWord = styled.span`
font-style: italic;
color: deeppink; 
`

const Home = () => {
  return (
    <Section id="home">

      <Container>

        <Suspense fallback={<Loading />}>
          <BigBox id="home-box">
            <TitleContainer> 
              <Title data-aos='fade-up'>The First magical play to earn gaming guild</Title>
            <SubText data-aos='fade-up'><KeyWord>Season 1</KeyWord></SubText>  
            </TitleContainer>
            <LineDrawToRight data-aos="fade-in"></LineDrawToRight>
            <SubText data-aos='fade-up'><ArrowImageForList src={logoArrow} />Mint your Rift Witch on the <KeyWord>Solana Blockchain </KeyWord></SubText>
            <SubText data-aos='fade-up'><ArrowImageForList src={logoArrow} />Join the community & become a <KeyWord>Rifter</KeyWord></SubText>
            <SubText data-aos='fade-up'><ArrowImageForList src={logoArrow} />Collaborate and shape the <KeyWord>RiftVerse</KeyWord> </SubText>
            </BigBox>
        </Suspense>
     {/*    <Box><Cristal src={cristal} /></Box> */}
      </Container>

    </Section>
  )
}

export default Home