import React, { Suspense } from 'react'
import styled from 'styled-components'
import Loading from '../Loading';

import RiftButton from '../RiftButton';
import riftPunkRace from '../../assets/Queen Galateia compressed.mp4';


//const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
width: 100%;
min-height: 66vh;
background-color: ${props => props.theme.body};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
padding-bottom: 6rem;
@media (max-width: 40em){
 height: 100%;
  padding-bottom: 0;
  padding-top: 1rem;
  
}
`

const Container = styled.div`
width: 100%;
margin: 0 auto;
display: flex;

justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 100%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 100%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 100%;
  }
}
`
const BoxDesktop = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 6rem;
@media (max-width: 40em){
  display: none;
  width: 100%;
  margin-top: 1rem;
}
`

const BoxMobile = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
margin-top: 1rem;
@media (min-width: 41em){
  display: none;
}
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 66%;
@media (max-width: 64em){
  width: 90%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`


const Quote = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 66%;
  font-style: italic;
@media (max-width: 64em){
  width: 90%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
margin: 1rem auto;

font-weight:400;
@media (max-width: 64em){
  width: 80%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}

`
const ButtonContainer = styled.div`
 width: 100%;
 margin: 1rem auto;
 display: flex;
 justify-content: center;
 display: none;

  @media (max-width: 64em){
    width: 100%;
    button{
      display:none;
      margin: 0 auto;
    }
  }
`

const ButtonContainerMobile = styled.div`
  display: none;
  @media (max-width: 40em){
    
    width: 100%;
    margin: 1rem auto;
    display: flex;
    justify-content: center;

    button{
      display: block;
      margin: 0 auto;
      margin: 1rem auto;
     }
}

`

const RiftPunkVideoContainer = styled.div`
width: 80%;
padding-left: 5rem;

video{
  width: 100%;
  width: -webkit-fill-available;
  object-fit: contain;
  overflow: hidden;
}

@media (max-width: 64em) {
  
  padding-left: 0;
  video{
    width: 100%;
    height: auto;
    object-fit: contain;
    overflow: hidden;
}


}
`
const KeyWord = styled.span`
font-style: italic;
color: deeppink; 
`


const OriginalsVideo = (props) => {
  return (
    <RiftPunkVideoContainer>
        <video  autoPlay muted loop playsInline>
          <source src={props.video} type="video/mp4"/>
        </video> 
    </RiftPunkVideoContainer>
  )
}

const OriginalSection = () => {
  return (
    <Section>
     <Title>Introducing The <KeyWord>Rift Punks</KeyWord></Title>
      <Container>
        <BoxDesktop>
          <Suspense fallback={<Loading />}>
           <OriginalsVideo video={riftPunkRace}/>
          </Suspense>
            <ButtonContainerMobile>
              <RiftButton text="Mint soon" ></RiftButton>
          </ButtonContainerMobile>
        </BoxDesktop>
        <BoxDesktop>
          <Quote sytle="italic">"Technology is our Magic"</Quote>
          <SubText>The first season also features a collection of <KeyWord>666</KeyWord> unique Punks they fused the power of their technology with the magic of the rift.</SubText>
          <SubText>Get yours to become a <KeyWord>Genesis Rifter</KeyWord> and let's build the <KeyWord>RiftVerse</KeyWord> together. </SubText>
            <ButtonContainer>
              <RiftButton text="Mint soon" ></RiftButton>
            </ButtonContainer>

        </BoxDesktop>

        /** Responsive mobile */
        <BoxMobile>
          <Quote sytle="italic">"Technology is our Magic"</Quote>
          <SubText>The first season also features a collection of <KeyWord>666</KeyWord> unique Punks they fused the power of their technology with the magic of the rift.</SubText>
          <SubText>Get yours to become a <KeyWord>Genesis Rifter</KeyWord> and let's build the <KeyWord>RiftVerse</KeyWord> together. </SubText>
            <ButtonContainer>
              <RiftButton text="Mint soon" ></RiftButton>
            </ButtonContainer>

        </BoxMobile>

        <BoxMobile>
          <Suspense fallback={<Loading />}>
           <OriginalsVideo video={riftPunkRace}/>
          </Suspense>
            <ButtonContainerMobile>
              <RiftButton text="Mint soon" ></RiftButton>
          </ButtonContainerMobile>
        </BoxMobile>



      </Container>
    </Section>
  )
}

export default OriginalSection;