import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color:  ${props => props.theme.background};
position: relative;
color: ${(props) => props.theme.text};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`
const KeyWord = styled.span`
font-style: italic;
color: deeppink; 
`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>
    
    <Container>
 
<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="What is Rift Of Magic?" >
    <KeyWord>Rift Of Magic</KeyWord> is Project developped with passion that aims to deliver a High quality and Magical MultiPlatform Independant Game experiences to the community. 
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="What is the RiftVerse?" >
  The  <KeyWord>RiftVerse</KeyWord> is a network focused on social connection. The Web 2 community on Twitter and on Discord and soon the 3D magical world will take place, where the NFT you acquired is your character. 
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="How to become a Rifter?" >
    Our community must remain as Magical as possible. 
    That's why, to protect the  <KeyWord>RiftVerse</KeyWord>, we have decided to restrict the access to only 666 passionate beings per season!
    Follow our Twitter and get noticed! 
  </Accordion>
</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="How can I use my NFT?" >
  In the upcoming seasons, you will be able to use your NFT as a 3D playable character in the  <KeyWord>RiftVerse</KeyWord> and in the future video games proposed by the Rift Builders.</Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="What is the Rift Nexus?" >
  The  <KeyWord>Rift Nexus</KeyWord> is a platform that links players and game developper in a win-win situation: builders build games, players play.</Accordion>

  <Accordion ScrollTrigger={ScrollTrigger} title="What is ¤Riftcrystal¤ and how to get some?">
  <KeyWord>¤Rift Crystal¤ ($RCY) </KeyWord>is the Governance Token of the  <KeyWord>RiftVerse</KeyWord>. 
    If you bought a Rift Geneis, 66.6 <KeyWord>¤RiftCrystal¤</KeyWord> will be airdropped to your wallet weekly. 
    Bonuses are given To whose who have adopted  <KeyWord>RiftMinions</KeyWord>. 
    You will also be able be rewarded in $RCY by acommplishing Quest on the Rift Nexus.
  </Accordion>

</Box>
  
    </Container>
    </Section>
  )
}

export default Faq