// This file contains variables for different themes

export const light = {
    
    body:"#fff",
    text:"#100126", // black shade
    background : '#0D0D0D', 
    bodyRgba : "255, 255, 255",
    textRgba:"32,32,32",
    carouselColor: '#EEEDDE',
    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',
    fontButton:'0.875em',
    navHeight: 'fit-content',
}


export const dark = {
    
    body:"#100126",
    text: "#fff", // black shade
    background : '#0D0D0D', 
    bodyRgba :"32,32,32" ,
    textRgba: "255, 255, 255",
    carouselColor: '#100126',
    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', // 1em = 16px
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',
    fontButton:'0.875em',
    navHeight: 'fit-content',
    roadmapBackground: "#fff"
}

export const riftOfMagicCustom = {
    body:"#100126",
    text: "#fff", // black shade
    background : '#0D0D0D', 
    bodyRgba :"32,32,32" ,
    textRgba: "255, 255, 255",
    carouselColor: '#100126',
    fontxs:'0.75em',
    fontsm:'0.875em',
    fontmd:'1em', 
    fontlg:'1.25em',
    fontxl:'2em',
    fontxxl:'3em',
    fontxxxl:'4em',
    fontButton:'0.875em',
    navHeight: 'fit-content',
    roadmapBackground: "#4f156b",
    roadmapFontTextColor: "#0D0D0D", 
    roadmapFontTitleColor: "#fff",
}

