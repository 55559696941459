import React from 'react';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import dedalio from '../../assets/Team/dedalio.png';
import alain from '../../assets/Team/alain.png';
import kedas from '../../assets/Team/kedas.png';
import daniel from '../../assets/Team/daniel.png';
import jowjow from '../../assets/Team/jowjow.png';
import amada from '../../assets/Team/amada.png';

import Twitter from '../../Icons/Twitter'
import Discord from '../../Icons/Discord' 
import Git from '../../Icons/Git'
import Instagram from '../../Icons/Instagram'

// import ConfettiComponent from '../Confetti';

//const ConfettiComponent = lazy(() => import("../Confetti"));


const Section = styled.section`
min-height: fit-content;
width: 100vw;
background-color: ${(props) => props.theme.body};

position: relative;
overflow: hidden;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em){
    font-size: ${(props) => props.theme.fontxl};

}
`;
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
margin: 1rem auto;

font-weight:400;
@media (max-width: 64em){
  width: 80%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}

`
const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-evenly;
align-items: center;
flex-wrap: wrap;

@media (max-width: 64em){
width: 80%;
}
@media (max-width: 48em){
width: 90%;
justify-content: center;
margin: 1rem auto;
}
`

const Item = styled.div`
width: 16vw;
padding: 1rem 0;
color: ${props => props.theme.body};
margin: 2rem 1rem;
position: relative;
z-index:5;

border: 2px solid ${props => props.theme.text};
border-radius: 20px;

&:hover{
  img{
    transform: translateY(-2rem) scale(1.2);
  }
}

@media (max-width: 30em){
width: 70vw;
}
@media (max-width: 64em){
  display:none;
  }

`

const ImageContainer = styled.div`
width: 66%;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 1rem;
img{
  width: 100%;
  height: auto;
}
`

const Name = styled.h2`
font-size: ${props => props.theme.fontlg};
display: flex;
align-items: center;
justify-content: center;
text-transform: uppercase;
color: ${props => props.theme.text};
margin-top: 1rem;
`

const Position = styled.h2`
font-size: ${props => props.theme.fontmd};
display: flex;
align-items: center;
justify-content: center;
text-align: center;
text-transform: capitalize;
color: ${props => `rgba(${props.theme.textRgba},0.9)`};
font-weight:400;
padding: 8px;
`

const IconList = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin: 1rem auto;

&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`
const TeamLegendMobile= styled.div`
position: relative;
    bottom: 40px;
    left: 26%;
    width: 48%;
    border-radius: 10px;
    background: rgb(0 0 0 / 40%);
    color: #fff;
    padding: 10px;
    font-size: 12px; 
    text-align: center;
    -webkit-transition: opacity .35s ease-in-out;
    transition: opacity .35s ease-in-out;

`
const MobileCarouselContainer = styled.div`
@media(min-width:64rem){
  display: none;
}
`
const MemberComponentDesktop = ({img, name=" ",position=" ", twitter, discord, git, instagram}) => {

  return(

    <Item>
     {img && <ImageContainer>
         <img width={500} height={400}  src={img} alt={name} />
      </ImageContainer> }
      <Name>{name}</Name>
      <Position>{position}</Position> 
      <IconList>
       {twitter &&<a href={twitter} target='_blank' rel="noopener noreferrer" aria-label='Twitter'>
          <Twitter />
        </a>}
        {discord && <a href={discord} target='_blank' rel="noopener noreferrer" aria-label='Discord'>
          <Discord />
        </a>}
        {git &&  <a href={git} target='_blank' rel="noopener noreferrer" aria-label='Github'>
          <Git />
        </a>}
        {instagram &&  <a href={instagram} target='_blank' rel="noopener noreferrer" aria-label='Instagram'>
          <Instagram />
        </a>}
        
</IconList>
    </Item>
  )
}


const MemberComponentMobile = ({img, name=" ",position=" ", twitter, discord, git, instagram}) => {

  return(
    <div>
      <ImageContainer>
      <img src={img} alt={name} />
      </ImageContainer>
      <TeamLegendMobile>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <IconList>
       {twitter &&<a href={twitter} target='_blank' rel="noopener noreferrer" aria-label='Twitter'>
          <Twitter />
        </a>}
        {discord && <a href={discord} target='_blank' rel="noopener noreferrer" aria-label='Discord'>
          <Discord />
        </a>}
        {git &&  <a href={git} target='_blank' rel="noopener noreferrer" aria-label='Github'>
          <Git />
        </a>}
        {instagram &&  <a href={instagram} target='_blank' rel="noopener noreferrer" aria-label='Instagram'>
          <Instagram />
        </a>}
        </IconList>
      </TeamLegendMobile>
    </div>
  )
}



const Team = () => {
  return (
    <Section id="team">
      <Title>Meet the core team</Title>
      <Container>
        <MemberComponentDesktop name="Hassan H." position="Founder" img={alain} twitter="https://twitter.com/SeaNouar"/>
        <MemberComponentDesktop name="Daniel Gaming" position="WEB3 & WEB2 Lead dev" img={daniel} git="https://github.com/danielgaming412/" discord="https://discord.com/users/974663544605917314"/>
        <MemberComponentDesktop name="Dedalio" position="Lead Artist"img={dedalio} twitter="https://twitter.com/dedalio_art" discord="https://discord.com/users/476369775489187841"/>
        <MemberComponentDesktop name="Amada" position="Marketing & Communication" img={amada} discord="https://discord.com/users/588448322818146309"/>
        <MemberComponentDesktop name="Kedas" position="IT strategy manager" img={kedas} twitter="https://twitter.com/Unda_dasea?t=B1wgngMLI_Km-7p-bfm8nQ&s=08" discord="https://discord.com/users/942162008424480878"/>
        <MemberComponentDesktop name="Jowjow" position="Discord Hero" img={jowjow} discord="https://discord.com/users/401440489355739136"/>   
       
        <MobileCarouselContainer>  
        <Carousel showThumbs={false}> 
          <MemberComponentMobile name="Hassan H." position="Founder" img={alain} twitter="https://twitter.com/SeaNouar"/>
          <MemberComponentMobile name="Daniel Gaming" position="WEB3 & WEB2 Lead dev" img={daniel} git="https://github.com/danielgaming412/" discord="https://discord.com/users/476369775489187841"/>
          <MemberComponentMobile name="Dedalio" position="Lead Artist"img={dedalio} twitter="https://twitter.com/dedalio_art" discord="https://discord.com/users/974663544605917314"/>
          <MemberComponentMobile name="Amada" position="Marketing & Communication" img={amada} discord="https://discord.com/users/588448322818146309"/>
          <MemberComponentMobile name="Kedas" position="IT strategy manager" img={kedas} twitter="https://twitter.com/Unda_dasea?t=B1wgngMLI_Km-7p-bfm8nQ&s=08" discord="https://discord.com/users/942162008424480878"/>   
          <MemberComponentMobile name="Jowjow" position="Discord Hero" img={jowjow} discord="https://discord.com/users/401440489355739136"/> 
        </Carousel>
        </MobileCarouselContainer>
      </Container>
    </Section> 
  )
}

export default Team
