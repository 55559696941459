import {Routes,Route} from "react-router-dom";

import GlobalStyles from "./styles/GlobalStyles";
import {riftOfMagicCustom } from "./styles/Themes";
import { ThemeProvider } from "styled-components"; 



// Below Imports using React.lazy and Suspence

// const Navigation = lazy(() => import("./components/Navigation"));
// const Home = lazy(() => import("./components/sections/Home"));
// const About = lazy(() => import("./components/sections/About"));
// const Roadmap = lazy(() => import("./components/sections/Roadmap"));
// const Team = lazy(() => import("./components/sections/Team"));
// const Showcase = lazy(() => import("./components/sections/Showcase"));
// const Faq = lazy(() => import("./components/sections/Faq"));
// const Footer = lazy(() => import("./components/Footer"));
// const ScrollToTop = lazy(() => import("./components/ScrollToTop"));

//import Navigation from "./components/Navigation";
import FirstStepNavigation from "./components/FirstStepNavigation";

//import About from "./components/sections/About";
import OriginalSection from "./components/sections/OriginalSection";
import RiftPunksSection from "./components/sections/RiftPunksSection";
import RiftMinionsSection from "./components/sections/RiftMinionsSection";
import Home from "./components/sections/Home";
import RiftOfMagic from "./components/sections/RiftOfMagic";
import Roadmap from "./components/sections/Roadmap";
//import ROMRoadMap from "./components/sections/ROMRoadMap"; 
import Team from "./components/sections/Team";
import Footer from "./components/Footer"; 
//import Showcase from "./components/sections/Showcase";
import Faq from "./components/sections/Faq";

import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init();
 
function App() {
  return (
        <Routes>
          <Route exact path="/" element={<MainPage/>}></Route>
          <Route path="/about" element={<OriginalSection/>}> </Route>
          <Route path="/roadmap" element={<Roadmap/>}></Route>
          <Route path="/*" element={<Page404/>}></Route>
        </Routes>
  );
}

function MainPage() {
  return (
    <main>
        <FirstStepNavigation />
         <RiftOfMagic />
        <Home />
        <OriginalSection />
        {/*<RiftPunksSection /> */}
        <RiftMinionsSection />
        <Roadmap />  
        <Team /> 
        <Faq />
        <Footer /> 
        
    </main>
  ); 
}

function Page404() {
  return (
    <main>
        <h2>Oups, are you lost?</h2>
    </main>
  ); 
}

export default App;
