import React, { Suspense } from 'react'
import styled from 'styled-components'
import Loading from '../Loading';
import serpent from '../../assets/snake crystal.png';
import dog from '../../assets/dog crystal punk.png';
const Section = styled.section`
width: 100%;
min-height: 66vh;
background-color: ${props => props.theme.background};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

`
const Container = styled.div`
width: 100%;
margin: 0 auto;

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 100%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 100%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 100%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  width: 100%;
}
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 66%;
  margin: 0 auto;

@media (max-width: 64em){
  width: 90%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
  margin: 1rem auto;

font-weight:400;
@media (max-width: 64em){
  width: 80%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}
`
const KeyWord = styled.span`
font-style: italic;
color: deeppink; 
`
const RiftMinionImage = styled.img`
width : inherit;
@media (max-width: 40em){
  width: 15rem;
  padding:2rem;
}

`

const RiftMinionsSection = () => {
  return (
    <Section id="about">
      <Container>
        <Box>
        <Suspense fallback={<Loading />}>
            <RiftMinionImage src={dog}/>
          </Suspense>
        </Box>
       
        <Box> 
        <Title>They come to serve.</Title>
          <SubTextLight>Introducing the <KeyWord>Rift Minions</KeyWord></SubTextLight>
          <SubText> Some Rift Witches are accompanied by loyal magical companions whose purpose is to harvest <KeyWord>¤RiftCrystal¤</KeyWord> for their owner.</SubText>
          <SubText> <KeyWord>Genesis Rifters</KeyWord> will be able to recruit more of them to increase their <KeyWord>¤RiftCrystal¤</KeyWord> harvesting potential.</SubText>
         </Box>

        <Box>
        <Suspense fallback={<Loading />}>
            <RiftMinionImage src={serpent}/>
          </Suspense>
        </Box>
      </Container>
    </Section>
  )
}

export default RiftMinionsSection;