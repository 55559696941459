import React, { Suspense, lazy  } from 'react'
import styled, { keyframes } from 'styled-components';
import Loading from '../Loading';

import HeaderVideo from '../../assets/HeaderLogoAnimation.mp4'
import HeaderMobileVideo from '../../assets/HeaderLogoAnimationMobile.mp4'
const HeaderLogoAnimation = lazy(() => import('../CoverVideo')); 

const Section = styled.section`
height: 100vh;
width: 100vw;
position: relative;
@media (max-width: 48em) {
  height: 80vh;
}
`

const Container = styled.div`
min-height: 100vh;
width: 100vw;
margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  width: 100%;
  align-items: baseline;
  margin-top: 3rem;
}
}

`

const HeaderBox = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 64em) {
  width: 100%;
align-items: baseline;
}
`
const arrowanim = keyframes`
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}`;


const Arrows = styled.svg`
width: 60px;
height: 72px;
position: absolute;
left: 50%;
margin-left: -30px;
bottom: 20px;
`
const Path= styled.path`
stroke: #fff;
fill: transparent; 
stroke-width: 1px;
-webkit-animation: ${arrowanim} 2s linear infinite; /* Safari */
animation: ${arrowanim} 2s linear infinite;
`

const RiftOfMagic = () => {
  return (
    <Section id="riftOfMagic">
      <Container>
      <HeaderBox>
      <Suspense fallback={<Loading />}>
          <HeaderLogoAnimation video={HeaderVideo} mobileVideo={HeaderMobileVideo}/>
      </Suspense>    
      </HeaderBox>
     
      <Arrows >
              <Path d="M0 0 L30 32 L60 0"></Path>
              <Path d="M0 20 L30 52 L60 20"></Path>
              <Path d="M0 40 L30 72 L60 40"></Path>
            </Arrows>
      
      </Container>
    
    </Section>
  )
}

export default RiftOfMagic