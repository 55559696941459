// Loading Component


import React from 'react';
import styled, { keyframes } from 'styled-components';
import crystal from "../hexCrystalTransparent.png"

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  /* background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.8)`}; */
  display: flex;
  justify-content: center;
  align-items: center;
`;


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`;
const Spinner = styled.div`
  margin-top: 1rem;
  width: 3rem;
  height: 3rem;
  -webkit-animation: ${spin} 1s linear infinite; /* Safari */
  animation: ${spin} 1s linear infinite;
`;

const CrystalToSpin = styled.img`
width: 3rem;
background-image: url(${crystal});
`

const Loading = () => {
 
    return (
      
        <Loader>
            <Spinner>
              <CrystalToSpin src={crystal}></CrystalToSpin>
            </Spinner>
        </Loader>
      
    );
  
};

export default Loading;
