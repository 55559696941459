import React from 'react'
import styled from 'styled-components'
import {Twitter} from '@styled-icons/bootstrap/Twitter';
import {Discord} from '@styled-icons/bootstrap/Discord';
import rarity from '../assets/raritysniperlogo.png';
import nftcalendar from '../assets/nft-calendar.png';

import original666 from '../assets/666original.png';

const Section = styled.section`
min-height: 60vh; 
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
color: ${(props) => props.theme.text};

display: flex;
flex-direction: column;
`

const Container = styled.div`
width: 75%;
margin: 2rem auto;
display: flex;
justify-content: center;
align-items: center;

border-bottom: 1px solid ${(props) => props.theme.text};

@media (max-width: 48em) {
width: 90%;

h1{
font-size: ${props => props.theme.fontxxxl};

}
}
`
const Center = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 48em) {
width: 100%;
}
`
const SubText = styled.h2`
margin-top: 2rem;
}`

const IconList = styled.div`
display: flex;
align-items: center;
margin: 1rem auto;
padding: 1rem; 
&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`
const Text = styled.p`
font-size: ${(props) => props.theme.fontsm};
`

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;

a{
  text-decoration:underline;
}
@media (max-width: 48em) {
flex-direction: column;
width: 100%;

span{
  margin-bottom: 1rem;
}
}
`
const RoadMapImageContainer = styled.img`
display: flex;
width: inherit;
width: 10rem;
`


const TwitterIcon=styled(Twitter)`
width: 2rem;`
const DiscordIcon=styled(Discord)`
width: 2rem;`

const RarityIcon=styled.img`
width: 4rem;`

const NftCalendarIcon=styled.img`
width: 6rem;`

const Footer = () => {
  return (
    <Section>
    <Container>
    <Center> 
    <RoadMapImageContainer src={original666}/>
    <Text>Rift Of Magic is a Next-Generation P2E NFT Project. It is community driven where the people called the Rifters decide what happens in the RiftVerse, their magical Metaverse and earn ¤RiftCrystal¤ their official currency.</Text>

    <SubText>The RiftVerse Starts here - Join us</SubText>

    <IconList>
      <a href="https://twitter.com/riftofmagic" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
       <TwitterIcon/>
      </a>
      <a href="https://discord.gg/MvBHpRjqna" target='_blank' 
      rel="noopener noreferrer"
      aria-label='Discord' 
      >
        <DiscordIcon />
      </a>

    </IconList>
    </Center>

      </Container>

    <Container>
    <Center> 
      <SubText>As Seen on</SubText>
      <IconList>
      <a href="https://raritysniper.com/nft-drops-calendar" target='_blank' 
        rel="noopener noreferrer"
       aria-label='rarity sniper'>
         <RarityIcon src={rarity} />
      </a>
      
      <a href="https://nftcalendar.io/" target='_blank' 
       rel="noopener noreferrer"
       aria-label='As seen on NFT Calendarr'>
         <NftCalendarIcon src={nftcalendar} />
      </a>
    </IconList>
    </Center>
    </Container>     
      <Bottom>
      <span>&copy; {new Date().getFullYear()} Rift Of Magic. All rights reserved.</span>
      </Bottom>
    </Section>
  )
}

export default Footer