import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef, lazy, Suspense } from "react";
import styled, { keyframes } from "styled-components";
import Background from '../../assets/HeaderBackground.jpg';
import logoArrow from '../../assets/logo-arrow.svg'
import original666 from '../../assets/666original.png';

import cristal from '../../hexCrystalTransparent.png'
const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-image: url(${Background});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.roadmapFontTitleColor};
  display: flex;

  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
    margin: 1rem auto;
  }
`;
const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;
const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;

  }
`;


const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-height: 50vh;
}
`

const BoxAnimation = styled.div`
width: 50%;
height: 38vh;   
object-fit: cover;
overflow: hidden;

@media (max-width: 40em){
  display: none;
}
`
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: white;
  margin: 1rem;
  @media (max-width: 40em) {
    text-align: center;
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

export const StyledRoadmapPNG = styled.div`
  margin: 0 auto;
  width: 100vw;
  background-image: url(${(props) => props.image});
  min-height: 159vh;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: url(${(props) => props.mobile});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 92vh;
    min-width: 100vw;
  }
`

const ArrowsList = styled.dl`
list-style-image: url("${logoArrow}");
text-align: justify;
@media (max-width: 40em) {
  text-align: center;
  }

`

const ArrowItem = styled.dt`
display: block;
color: white;
line-height: 2;
font-size: ${(props) => props.theme.fontlg};
font-weight: 600;
display: flex;
flex-direction: row;
align-items: center;
@media (max-width: 768px) {
  font-size: ${(props) => props.theme.fontmd};
  text-align: center;
  justify-content: center;
  color: deeppink
}

`

const StepDescr = styled.dd`
display: block;
color: white;
line-height: 1.5;
padding-left: 4rem;

@media (max-width: 40em) {
font-size: ${(props) => props.theme.fontmd};
padding-top: 1rem;
padding-bottom: 1rem;
padding-left: 0;
}

@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
}
`


const RoadMapItem = ({ title, subtext, addToRef }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <SubTitle>{title} </SubTitle>
          <Text>{subtext}</Text>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const RoadmapContainer = styled.div`
width: 80%;
position: relative;
margin: 0 auto;
display: flex;
flex-direction: column;

`

const RoadmapRow = styled.div`
display: flex;
flex-direction: column;
align-items: center;
height: fit-content;
color: rgba(0, 0, 0, 0.7);
border-radius: 25px;
padding: 20px;
`

const Card = styled.div`
display: flex;
flex-direction: column;
width: 45rem;
align-items: ${(props) => props.align};
height: fit-content;
background-color: rgba(0, 0, 0, 0.7); 
color: rgba(0, 0, 0, 0.7);
border-radius: 25px;
  padding: 20px;

  @media (max-width: 40em) {
    width: 80vw;
  }
`

const RoadMapImageContainer = styled.img`
display: flex;
width: 10rem;
position: absolute;
`

const GlowAnimation = keyframes`
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  
}`

const CardTitle = styled.span`
text-align: center;
font-size: ${(props) => props.theme.fontxl};
text-transform: capitalize;
color: ${(props) => props.theme.roadmapFontTitleColor};

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`
const SubtitleGlowAnimation = keyframes`
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #0000FF, 0 0 40px #0000FF;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6;
  
}`
const CardSubTitle = styled.span`
display: block;
font-size: ${(props) => props.theme.fontlg};
text-transform: capitalize;
color: ${(props) => props.theme.roadmapFontTitleColor};
-webkit-animation: ${SubtitleGlowAnimation} 1s ease-in-out infinite alternate;
-moz-animation: ${SubtitleGlowAnimation} 1s ease-in-out infinite alternate;
animation : ${SubtitleGlowAnimation} 1s ease-in-out infinite alternate;

@media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
    padding-bottom: 1rem; 
    padding-top: 1rem; 

  }
`
const ArrowImageForList = styled.img`
width: 3rem;
transform: rotate(-44deg);
margin-right: 1rem;
@media (max-width: 40em) { 
  display : none;
}
`

const CardHeader = styled.div`
width: -webkit-fill-available;
text-align: -webkit-center;
`
const CardBody = styled.div`
display: flex;
justify-content: space-between;
@media (max-width: 40em) { 
  display : block;
}
`

const KeyWord = styled.span`
font-style: italic;
color: deeppink; 
`
const Cristal = styled.img`
  width: 3rem;
  height: auto;
  place-self: center;
  @media (max-width: 40em){
 width: 3rem;

}
}`


const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",

          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
            // markers:true,
          },
        }
      );
    });




    return () => {
      if (t1) t1.kill();
    };
  }, []);

  return (
    <Section id="roadmap">
      <Title>Seasons</Title>
      <RoadmapContainer>
        <RoadmapRow align='start' data-aos="fade-right"  data-aos-duration="500">
        <Card >
          <CardHeader>
            <CardTitle>Season 1</CardTitle>
            <CardSubTitle>Opening The Rift</CardSubTitle>
          </CardHeader>
          <CardBody>
            <ArrowsList>
        
              <ArrowItem><ArrowImageForList src={logoArrow} />The Art</ArrowItem>
              <StepDescr>Artist working on the assets of the Rift Witches and the Rift Minions and drawing more than 100 unique traits</StepDescr>

              <ArrowItem><ArrowImageForList src={logoArrow} />The 666 Rift Witches</ArrowItem>
              <StepDescr>Generating the 666 NFTs with their metadata for the Solana Blockain</StepDescr>

              <ArrowItem><ArrowImageForList src={logoArrow} />Metaplex</ArrowItem>
              <StepDescr>Developing the smart contract with Metaplex and Sugar</StepDescr>

              <ArrowItem><ArrowImageForList src={logoArrow} />Whitepaper & website</ArrowItem>

              <ArrowItem><ArrowImageForList src={logoArrow} />Community</ArrowItem>
              <StepDescr>Building the community on Discord, Twitter & Reddit</StepDescr>

            </ArrowsList>
          </CardBody>
        </Card>
       
        </RoadmapRow>
        <Cristal src={cristal} data-aos="zoom-in" data-aos-duration="200"/>
        <RoadmapRow align='end' data-aos="fade-left"  data-aos-duration="500">
        <Card >
          <CardHeader>
            <CardTitle>Season 2</CardTitle>
            <CardSubTitle>Tokenomics 666</CardSubTitle>
          </CardHeader>
          <ArrowsList>
            
          <ArrowItem><ArrowImageForList src={logoArrow} />The 666 Rift Punks</ArrowItem>
              <StepDescr>New collection of Genesis 666 NFTs - Welcome to the Rift Punks</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />¤RiftCrystal¤</ArrowItem>
            <StepDescr>The Rift Crystal Token is created and serve the Economy and the Governance of the RiftVerse</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />Governance by the holders</ArrowItem>
            <StepDescr>Governance by the Token is introduced, Rifters can vote and decide on each decision regarding Rift Of Magic</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />Token Yield</ArrowItem>
            <StepDescr>Every holder sees an airdrop of 66.6 Rift Crystals every week. Rift Minion holders have bonuses.</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />Alpha of The Rift Nexus</ArrowItem>
            <StepDescr>The Rift Nexus is released in alpha stage and submissions for independent games are open</StepDescr>
          
          </ArrowsList>
        </Card>
    </RoadmapRow>
    <Cristal src={cristal} data-aos="zoom-in" data-aos-duration="200" />
    <RoadmapRow align='start' data-aos="fade-right"  data-aos-duration="500">
        <Card >
          <CardHeader>
            <CardTitle>Season 3</CardTitle>
            <CardSubTitle>The RiftVerse is getting Ready</CardSubTitle>
          </CardHeader>
          <ArrowsList>

          <ArrowItem><ArrowImageForList src={logoArrow} />The 666 new Genesis Collection</ArrowItem>
          <StepDescr>New collection of Genesis 666 NFTs incoming</StepDescr>

          <ArrowItem><ArrowImageForList src={logoArrow} />Your NFT = Your playable character</ArrowItem>
          <StepDescr>Every NFT is Low Poly Modelled and can be claimed by the original owner</StepDescr>

          <ArrowItem><ArrowImageForList src={logoArrow} />A new home - The RiftVerse</ArrowItem>
          <StepDescr>Alpha 3D Rift of Magic open World (like VR chat) for the Genesis Holders</StepDescr>

          <ArrowItem><ArrowImageForList src={logoArrow} />Genesis Round table</ArrowItem>
          <StepDescr>Genesis Holder's Round table to reviews, discuss and vote for the first Independent game of the week</StepDescr>

          <ArrowItem><ArrowImageForList src={logoArrow} />Play & Earn</ArrowItem>
          <StepDescr>Play & Earn mechanics is live in beta testing with side quests attached to the games of The Rift Nexus</StepDescr>

          </ArrowsList>
        </Card>
        </RoadmapRow>
        <Cristal src={cristal} data-aos="zoom-in" data-aos-duration="200" />
        <RoadmapRow align='end' data-aos="fade-left" data-aos-duration="500">
        <Card >
          <CardHeader>
            <CardTitle>Season 4</CardTitle>
            <CardSubTitle>Expansion</CardSubTitle>
          </CardHeader>
    
          <ArrowsList>
          <ArrowItem><ArrowImageForList src={logoArrow} />Last 666 Genesis collection</ArrowItem>

            <ArrowItem><ArrowImageForList src={logoArrow} />Beta of The Rift Nexus</ArrowItem>
            <StepDescr>Opening the beta of the Rift Nexus to a wider group of users</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />Last Yields incoming</ArrowItem>
            <StepDescr>Last round of Rift Crystal yields for the Rift Genesis and Rift Minions Holders</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />Alpha of The Rift Art station</ArrowItem>
            <StepDescr>Artist can create, buy and sell assets that can be used on the Riftverse and specially inside the independent games.</StepDescr>

          </ArrowsList>
        </Card>
        </RoadmapRow>
        <Cristal src={cristal} data-aos="zoom-in" data-aos-duration="200"/>
        <RoadmapRow align='start' data-aos="fade-right" data-aos-duration="500">
        <Card>
          <CardHeader>
            <CardTitle>Season 5</CardTitle>
            <CardSubTitle>Beyond</CardSubTitle>
          </CardHeader>
          <ArrowsList>
            <ArrowItem><ArrowImageForList src={logoArrow} />No more Rift Genesis Collection</ArrowItem>
            <StepDescr>New NFTs collections are introduced to the public with higher supply, those won't have the perks of the Genesis NFTs.</StepDescr>

            <ArrowItem><ArrowImageForList src={logoArrow} />Preparing for the Beta public launch of The Rift Nexus</ArrowItem>
            <StepDescr>Rift Nexus is tested by Rift Genesis owners, we gather feedback, solve critical issues and release the product to the public. We keep a continuous development process.  </StepDescr>
            
            <ArrowItem><ArrowImageForList src={logoArrow} />The right to become a Rifter</ArrowItem>
            <StepDescr>The Rift Genesis holders will get shares of the revenue generated by the Rift Nexus, the older is your NFT the higher is the share percentage</StepDescr>

          </ArrowsList>
        </Card>
        </RoadmapRow >
      </RoadmapContainer>
    </Section>
  );
};

export default Roadmap;
