import React from 'react'
import styled, { keyframes } from 'styled-components'

const kfRFT = keyframes`
0% {
  background-position: 0%;
 }

 100% {
  background-position: 400%;
 }
`
const RFTBTN = styled.button`
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 14px;
  font-family: inherit;
  color: #fff;
  width: 9em;
  height: 3em;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
 }
 
 &::hover {
  animation: ani 8s linear infinite;
  border: none;
 }

 animation: ${kfRFT}
 
 @keyframes ani {
  0% {
   background-position: 0%;
  }
 
  100% {
   background-position: 400%;
  }
 }
 
 &::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
 }
 
 &:hover::before {
  filter: blur(20px);
 }
 
 &::active {
  background: linear-gradient(32deg,#03a9f4,#f441a5,#ffeb3b,#03a9f4);
 }
 `

 const RiftButton = ({text, link}) => {
  return (
<a href={link} aria-label={text} target="_blank" rel="noreferrer" >
<RFTBTN>{text}</RFTBTN> 
</a>
    
  )
}

export default RiftButton